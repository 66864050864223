.section1 {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.section1 video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -2;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 5%;
  color: white;
}

.left {
  flex: 1;
}

.left h1 {
  font-size: 43px;
}

.left p {
  font-size: 20px;
  line-height: 1.5;
}

.right {
  flex: 1;
  text-align: center;
}

.right img {
  max-width: 65%;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    text-align: center;
    padding: 0 10%;
    padding-top: 80px;
  }

  .right img {
    margin-top: -50px;
    max-width: 80%;
  }

  .left h1 {
    font-size: 25px;
  }

  .left p {
    font-size: 15px;
    line-height: 1.5;
  }
}
