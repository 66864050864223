.section4 {
  padding: 80px 5%;
  text-align: center;
  background-color: #e4e4e4;
}

.section4 h2 {
  font-size: 35px;
  margin-bottom: 30px;
}

.email_container {
  display: flex;
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}

.email_container a {
  color: rgb(43, 80, 135);
  text-decoration: none;
}

.section4 p {
  font-size: 1.2em;
}

.social-links {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.social-links a {
  margin: 10px 15px;
  font-size: 1.2em;
}

.download-button {
  display: inline-block;
  margin-top: 30px;
  padding: 15px 30px;
  background-color: #23516f;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .section4 p {
    font-size: 1em;
  }

  .download-button {
    width: 80%;
  }
}
