nav {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  height: 80px;
  padding-left: 20px;
  padding-right: 20px;
}

.logo {
  color: white;
  font-size: 1.5em;
  flex: 1;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  flex: 2;
}

.nav-links a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  font-weight: bold;
}

.hamburger {
  display: none;
  font-size: 2em;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    top: 60px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    width: 100%;
    height: calc(100% - 60px);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .nav-links.open {
    transform: translateX(0);
  }

  .nav-links a {
    margin: 20px 0;
    text-align: center;
    font-size: 1.5em;
  }

  .hamburger {
    display: block;
  }
}
