@import url("https://fonts.googleapis.com/css?family=Asap:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:wght@600&family=Poppins:wght@400;600&display=swap");

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Asap";
}

img {
  max-width: 100%;
  height: auto;
}
