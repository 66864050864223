.section2 {
  padding: 80px 5%;
  background-color: #f9f9f9;
}

.section2 h2 {
  font-size: 35px;
  text-align: center;
  margin-bottom: 50px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 20px;
}

.card {
  width: 300px;
  background: white;
  margin: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  padding-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.card:hover {
  transform: scale(1.1);
}

.card img {
  width: 100%;
  height: 160px;
}

.card-content {
  padding: 15px;
}

.card-content h3 {
  margin: 0 0 10px 0;
}

.card-content p {
  margin: 0;
  line-height: 1.5;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: 400px;
  }

  .card img {
    height: 200px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .card {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .card {
    width: 90%;
  }
}
