.section3 {
  padding: 80px 5%;
}

.section3 h2 {
  font-size: 35px;
  text-align: center;
  margin-bottom: 50px;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service {
  width: 400px;
  text-align: center;
  margin: 15px;
}

.service img {
  width: 300px;
  height: 200px;
  border-radius: 10px;
}

.service h3 {
  margin-top: 20px;
}

.service p {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .service {
    width: 100%;
    max-width: 400px;
  }
}
